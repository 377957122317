// https://coolors.co/201e1f-ff4000-ac9aa8-008f8f-f5bb00-018e42
$primary: #008F8F;
$success: #018E42;
$warning: #F5BB00;
$danger: #FF4000;
$secondary: #AC9AA8;
$info: #201E1F;

$theme-colors: (
  "primary": $primary,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "secondary": $secondary,
  "info": $info
);

$breadcrumb-divider: quote(">");
$breadcrumb-divider-color: $secondary;
