@import '../node_modules/bootstrap/scss/functions';
@import './variables';
@import '../node_modules/bootstrap/scss/bootstrap';

body {
  font-family: 'Josefin Sans', sans-serif !important;
}

.spinner-border {
  //margin-top: 30px;
  //margin-bottom: 30px;
  color: $primary;
}
